<template>
  <section>
    <div class="panel-content" :style="panelStyle">
      <div v-if="busy" class="loading">
        <div>
          <i class="fa fa-refresh fa-spin"></i>
        </div>
      </div>
      <EquipmentHistoryChartDisplay
        v-if="(requireRTValues && dataset) || !busy"
        :dataset="dataset"
        :widgetOptions="chartOptions"
        :generateImage="generateImage"
        :disconnection="disconnection"
        :namedQuery="namedQuery"
      />
    </div>
    <div v-if="showCustomToolbar" class="panel-toolbar">
      <slot name="toolbar"></slot>
    </div>
    <EquipmentDisconnectionLogger
      ref="connLogger"
      :panelOptions="panelOptions"
      @ready="disconnectionDataReady = $event"
    />
  </section>
</template>

<script>
import {debounce, isEqual} from "lodash";
import EquipmentHistoryChartDisplay from "@/components/equipment-history-chart-display.vue";
import ChartForm from "@/components/control-sidebar/property-editors/chart-form.vue";
import {defSerie} from "@/components/control-sidebar/property-editors/detail-form-chart.vue";
import EquipmentDisconnectionLogger from "@/components/equipment-disconnection-logger.vue";
export default {
  name: "EquipmentHistoryChartPanel",
  props: {
    equipment: {
      type: Object,
      required: false,
      default: () => null
    },
    display: {
      type: Object,
      required: true
    },
    panel: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  components: {
    EquipmentHistoryChartDisplay,
    EquipmentDisconnectionLogger
  },
  data() {
    return {
      dataset: null,
      refreshing: false,
      hasSamples: false,
      maximumValues: 100,
      minimumValues: 3,
      disconnection: null,
      disconnectionDataReady: null
    };
  },
  computed: {
    contractId() {
      return this.$store.getters["user/contract"]?.id || null;
    },
    generateImage() {
      return this.$route.query?.media == "print";
    },
    panelOptions() {
      var panel = this.panel || null;
      return (panel && panel.options) || null;
    },
    panelStyle() {
      let parentStyle = this?.$parent?.panelStyle || {};
      let localStyle = {};
      localStyle = {};
      localStyle["min-height"] = parentStyle["min-height"]
        ? parentStyle["min-height"]
        : "auto";
      parentStyle["height"] = "inherit";
      // localStyle["background-color"] = "#ffffff";
      localStyle["overflow"] = "hidden";
      return localStyle;
    },
    dataSetConfig() {
      return this?.panelOptions?.data || [];
    },
    chartOptions() {
      return this?.panelOptions?.chartOptions || null;
    },
    onlyPie() {
      return (this.dataSetConfig || [])
        .filter(({enabled}) => enabled)
        .every((serie) => serie?.chartOptions?.type == "pie");
    },
    pendingIds() {
      return (
        (this.datasetIdList.length &&
          this.$store.getters[`${this.namedQuery || "history"}/pending`]) ||
        []
      );
    },
    hasDataList() {
      return this.$store.getters["dashboard/hasResourceFrom"](
        "data",
        this.equipment?.id
      );
    },
    historyInterval() {
      return (
        this.$store.getters[`${this.namedQuery || "history"}/interval`] || null
      );
    },
    busy() {
      return (
        (this.pendingIds.length > 0 &&
          (!this.panelOptions.realtime || this.mode == "editor")) ||
        this.refreshing
      );
    },
    sidebar() {
      return (
        this.$store.getters["dashboard/sidebar"] || {
          name: "unknown"
        }
      );
    },
    requireRTValues() {
      return this.panelOptions.realtime;
    },
    RTvalues() {
      if (!this.requireRTValues) return null;
      let entry = {};
      this.datasetDataList.forEach((item) => {
        entry[item.id] = item?.current_value?.value || null;
      });
      return entry;
    },
    showCustomToolbar() {
      return (this?.panel?.toolbar || []).length > 0;
    },
    connectorList() {
      return this.$store.getters["dashboard/connectorList"] || [];
    },
    isDatasetReady() {
      if (this.busy) return false;
      if (!(this.datasetIdList || []).length) return false;
      const status = this.$store.getters[
        `${this.namedQuery || "history"}/ready`
      ];
      return status &&
        this.historyInterval &&
        this.historyInterval.start &&
        this.historyInterval.end &&
        this.datasetIdList.some((id) => status[id])
        ? true
        : false;
    },
    namedQuery() {
      return this?.panelOptions?.namedQuery || "";
    },
    datasetColumns() {
      if (this.namedQuery) {
        return this.$store.getters[`${this.namedQuery}/columns`] || [];
      } else {
        return this.dataSetConfig || [];
      }
    },
    datasetIdList() {
      return this.$utils.distinct(
        (this.dataSetConfig || []).map((i) => parseInt(i.data_id))
      );
    },
    datasetDataList() {
      return (this.$store.getters["dashboard/dataList"] || []).filter(
        ({id}) => this.datasetIdList.indexOf(parseInt(id)) >= 0
      );
    },
    history() {
      if (!this?.datasetDataList?.length) return null;
      let entries =
        this.$store.getters[
          this.namedQuery
            ? `${this.namedQuery}/aggregatedEntries`
            : "history/entries"
        ] || {};
      if (this.namedQuery) return entries;
      let ret = {};
      this.datasetIdList.forEach((id) => {
        if (entries[id]) ret[id] = entries[id];
      });
      return ret;
    },
    localFetch() {
      return !(this.mode == "editor" || this.namedQuery);
    },
    axisSupport() {
      return this.onlyPie
        ? false
        : this.hasSamples || this.disconnection
        ? true
        : false;
    }
  },
  watch: {
    historyInterval: {
      handler(n, o) {
        if (isEqual(n, o)) return;
        if (!n && o) {
          this.dataset = null;
        }
        if (this.mode == "editor") return; // it should make use of simulated samples only
        if (this.$el && n && n.start && n.end) {
          if (this.$refs.connLogger) {
            let ids = this.$utils.distinct(
              (this.datasetDataList || []).map(
                ({device}) => device.connector.id
              )
            );
            this.$refs.connLogger.fetchDisconnection(ids, this.historyInterval);
          }
          this.fetch();
        }
      },
      deep: true
    },
    chartOptions: {
      handler(n, o) {
        if (this.$el && n && o && this.isEditing && !isEqual(n, o)) {
          this.delayedRefresh();
        }
      },
      deep: true
    },
    dataSetConfig: {
      handler(n, o) {
        if (this.$el && n && this.mode == "editor") {
          let a = (n || []).map(({data_id}) => data_id).join(",");
          let b = (o || []).map(({data_id}) => data_id).join(",");
          if ((this.dataset || []).length && a != b) {
            this.parseEquipmentDataSamples();
          } else {
            this.delayedRefresh();
          }
        }
      },
      deep: true
    },
    isEditing: {
      handler(n, o) {
        if (n && !o) {
          if (this.sidebar.name != "ChartForm") {
            this.$emit("initCustomProperties", {
              panelName: this.panel.name,
              propertyEditor: ChartForm
            });
            // this.$nextTick(() => {
            //   this.setSideBar();
            // });
            // this.delayedRefresh();
          }
        }
      },
      immediate: true
    },
    dataList: {
      deep: true,
      handler(n, o) {
        if (isEqual(n, o)) return;
        if (n && this.mode == "viewer" && !this?.dataset?.length) {
          this.setupDatasetForTrend();
        }
      }
    },
    isDatasetReady: {
      handler(n, o) {
        if (!this.$el || !n || isEqual(n, o)) return;
        this.validateDataSet();
      },
      immediate: true
    },
    disconnectionDataReady: {
      handler(n, o) {
        if (isEqual(n, o)) return;
        if (!this.$el || !n) return;
        this.validateDataSet();
      }
    },
    RTvalues: {
      handler(n, o) {
        if (isEqual(n, o)) return;
        if (n) {
          this.delayedRefresh();
        }
      },
      immediate: true,
      deep: true
    }
    // pendingIds(n, o) {
    //   if (o?.length && !n?.length) {
    //     this.validateDataSet();
    //   }
    // }
  },
  methods: {
    fetch() {
      // at editor mode, a simulation will be made. NamedQueries are globally fetched
      if (!this.localFetch) return;
      this.$store.dispatch("history/fetch", this.datasetIdList);
    },
    parseEquipmentDataSamples() {
      let dataset = [];
      let hasSamples = false;
      let data, dataId, samples, cfg, history;
      const dataList = this.datasetDataList;
      (this.datasetColumns || []).forEach((column, ix) => {
        dataId = parseInt(column.data_id);
        data = dataList.find(({id}) => parseInt(id) == dataId);
        history =
          (data && (this?.history || {})[this.namedQuery ? ix : dataId]) || {};
        samples = history?.samples ?? [];
        if (!this?.dataSetConfig[ix]) return;
        cfg = JSON.parse(
          JSON.stringify({
            ...defSerie(this?.dataSetConfig[ix].chartOptions.type),
            ...(this?.dataSetConfig[ix].chartOptions || {})
          })
        );
        if (!("enabled" in cfg.itemStyle)) {
          cfg.itemStyle.enabled = true;
        }
        if (!cfg.itemStyle.enabled) return;
        if (!cfg.name) {
          cfg.name = data.name;
        }
        if (cfg.type == "pie") {
          if (this.namedQuery) {
            let pieCfg = structuredClone(cfg);
            pieCfg.data = samples.map((sample) => ({
              name: sample.key,
              value: sample.value
            }));
            dataset.push(pieCfg);
            hasSamples = true;
          } else {
            let pieCfg =
              (dataset || []).find((i) => i.type == "pie") ||
              JSON.parse(JSON.stringify(cfg));
            let value = data?.current_value ? data.current_value.value : 0;
            if (!isNaN(Number(value))) {
              let name = `${cfg.name || data.name}`;
              let nTimes = (pieCfg.data || []).filter(
                (item) => item.name.replace(/^\s+/g, "") == name
              ).length;
              pieCfg.data.push({
                name: `${Array(nTimes + 1).join(" ")}${cfg.name || data.name}`, // must be unique name
                value: parseFloat(value),
                label: {
                  show: cfg.label.show,
                  color: cfg.label.color,
                  position: cfg.label.position,
                  dataLabelFormatter: cfg.label.formatter,
                  dataLabelFormatterIndex: pieCfg.data.length
                },
                labelLine: {
                  show: cfg.label.show,
                  lineStyle: {
                    color: cfg.label.color
                  }
                },
                history: history,
                expression: cfg.itemStyle.expression ?? "",
                validation: cfg.validation ?? ""
              });
              if (pieCfg.data.length == 1) {
                pieCfg.color = [];
              }
              pieCfg.color.push(cfg.itemStyle.color);
              if (pieCfg.data.length == 1) {
                pieCfg.animation = cfg.animation;
                pieCfg.hoverAnimation = cfg.hoverAnimation;
                dataset.push(pieCfg);
              }
              pieCfg.history = history;
              hasSamples = true;
            }
          }
        } else {
          if (!(data && samples.length > 0)) return;
          if (samples.length) {
            hasSamples = true;
            cfg.data = samples.map((item) => {
              let value = parseFloat(item.value);
              let name = this.namedQuery ? item.key : item.time;
              return {
                name: name,
                value: [name, value],
                data_id: data.id
              };
            });
          }
          if (this.$store.getters.print) {
            cfg.animation = false;
          }
          dataset.push(cfg);
        }
      });
      // let pies = (dataset || []).filter((i) => i.type == "pie");
      // if (pies.length) {
      //   pies.forEach((pie) => {
      //     delete pie.itemStyle;
      //   });
      // }
      // if it has only pie does not show axles
      this.hasSamples = hasSamples;
      this.$set(this, "dataset", dataset);
      if (this.mode == "editor") {
        this.$nextTick(() => {
          this.$root.$emit("dashboard:editor", {
            action: "updateWidth"
          });
        });
      }
    },
    setupDatasetForTrend() {
      if (this.dataset?.length) return;
      this.refreshing = true;
      let dataset = [];
      let hasSamples = false;
      this.hasSamples = false;
      this.datasetIdList.forEach((id, ix) => {
        let data = this.datasetDataList.find((data) => data.id == id);
        if (!data) return;

        let samples;
        if (this.mode == "editor") {
          samples = data?.history?.samples ?? [];
        } else if (this.chartOptions.trailingValue && data?.current_value) {
          samples = [
            data.current_value,
            {date_time: moment().format(), value: data.current_value.value}
          ];
        } else {
          samples = [];
        }

        let cfg = this.dataSetConfig[ix];
        if (!cfg) return;

        cfg = {
          ...defSerie(cfg.chartOptions.type),
          ...(cfg.chartOptions || {})
        };
        if (!("enabled" in cfg.itemStyle)) {
          cfg.itemStyle.enabled = true;
        }
        if (!cfg.itemStyle.enabled) return;
        if (!cfg.name) {
          cfg.name = data.name;
        }

        // boolean type default treatment
        if (cfg.lineStyle) {
          switch (cfg.lineStyle.waveForm) {
            case "square":
              cfg.step = "end";
              cfg.smooth = false;
              break;
            case "triangle":
              cfg.step = false;
              cfg.smooth = false;
              break;
            case "sin":
              cfg.step = false;
              cfg.smooth = true;
              break;
          }
        }
        if (cfg.validation) {
          samples = samples.filter((sample) => {
            return this.$utils.isTrue(cfg.validation, {
              $value: sample.value
            });
          });
        }
        cfg.data = samples.map((sample) => {
          hasSamples = true;
          return [sample.date_time, parseFloat(sample.value)];
        });
        dataset.push(cfg);
      });
      this.$set(this, "dataset", dataset);
      this.refreshing = false;
      if (this.requireRTValues) {
        this.hasSamples = dataset.length > 0;
        this.setupRefreshTimer();
      } else {
        this.hasSamples = hasSamples;
      }
    },
    onEdit() {
      if (this.mode != "editor") return;
      this.trigger({
        action: "chart:activate",
        details: {panelName: this.panel.name}
      });
    },
    trigger(details) {
      this.setSideBar();
      this.$nextTick(() => {
        this.$root.$emit("chart:event", details);
      });
    },
    setSideBar() {
      if (this.sidebar.name != "ChartForm") {
        this.$root.$emit("controlSidebar:setContent", ChartForm);
      }
    },
    refresh() {
      if (this.panelOptions.realtime) {
        if (!this.RTinit) {
          this.RTinit = true;
          this.setupDatasetForTrend();
        } else if (!this.isEditing) {
          // updates series with current values
          this.dataset.forEach((d) => {
            const data = this.datasetDataList.find(({name}) => name == d.name);
            if (data?.current_value?.value) {
              let time = moment().format();
              // prevent ocasional pushes of values with the same time
              if (d.data.at(-1)?.[0] != time) {
                d.data.push([time, parseFloat(data.current_value.value)]);
                d.itemStyle = d.itemStyle || {};
                d.itemStyle.rt = true;
              }
            }

            // check if first value is older than timeWindow
            // and there is at least the <minimum> amount of values
            // OR
            // there is more than the <maximum> amount
            if (
              (d.data.length >= this.minimumValues &&
                moment(d.data.at(-1)[0])
                  .subtract(this.chartOptions.timeWindow, "seconds")
                  .isAfter(d.data.at(0)[0])) ||
              d.data.length > this.maximumValues
            ) {
              d.data.shift();
            }
          });
        }
      }
      this.validateDataSet();
    },
    setupRefreshTimer() {
      if (!this.dataset.length || this.mode != "viewer" || this._refreshTimer)
        return;
      this._refreshTimer = setInterval(
        this.refresh.bind(this),
        this.chartOptions.refreshInterval * 1000
      );
    },
    fetchDataList() {
      var query = {
        resource: "data",
        connectorId: this.equipment?.id,
        forceUpdate: false,
        once: true
      };
      return this.$store.dispatch("dashboard/fetchResourcesFrom", query);
    },
    validateDataSet() {
      if (
        !this.$el ||
        !this.isDatasetReady ||
        (!this.isEditing && this.panelOptions.realtime)
      )
        return;
      this.disconnection =
        this.$refs.connLogger && this.disconnectionDataReady
          ? this.$refs.connLogger.disconnection
          : null;
      this.parseEquipmentDataSamples();
    }
  },
  mounted() {
    if (this.mode != "editor") {
      this.validateDataSet();
    } else {
      this.refresh();
    }
  },
  created() {
    if (this.equipment && !this.hasDataList) {
      this.fetchDataList();
    }
  },
  beforeCreate() {
    this.delayedRefresh = debounce(() => {
      this.refresh();
    }, 500);
  },
  beforeDestroy() {
    this.delayedRefresh = null;
  }
};
</script>

<style scoped>
.section {
  position: relative;
}
.loading {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  align-content: center;
  height: inherit;
  font-size: 20pt;
}

.loading > div {
  flex: 1 1 inherit;
  align-self: center;
}

.panel-title {
  padding-left: 10px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.panel-toolbar {
  position: absolute;
  top: 3px;
  left: 0px;
  background-color: transparent;
}

.panel-content {
  z-index: 0;
  min-height: 200px;
  height: 100%;
}
</style>
